import { DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { selectPayRecordByPagination } from '@/api/contract'; //表头

const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 80,
  align: 'center',
  fixed: 'left'
}, {
  title: '收支节点',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDesc',
    width: 320 // slots: { customRender: 'nodeDescriptor' },

  }, {
    title: '计划收支时间',
    dataIndex: 'planPayTime',
    width: 120,
    align: 'center' // key: 'paymentTime',
    // slots: { customRender: 'paymentTime' },

  }]
}, {
  title: '收支条件',
  dataIndex: 'payTerms',
  width: 150,
  align: 'center'
}, {
  title: '收支金额(元)',
  dataIndex: 'payAmount',
  width: 120,
  align: 'center'
}, {
  title: '合同总金额(元)',
  dataIndex: 'totalAmount',
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'payProportion',
  slots: {
    customRender: 'payProportion'
  },
  width: 120,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  width: 200
}, {
  title: '实际收支金额(元)',
  dataIndex: 'actualPayAmount',
  width: 150,
  align: 'center'
}, {
  title: '实际累计收支比例',
  dataIndex: 'payTotalProportion',
  slots: {
    customRender: 'payTotalProportion'
  },
  width: 150,
  align: 'center'
}, {
  title: '收支状态',
  dataIndex: 'payState',
  slots: {
    customRender: 'payState'
  },
  width: 90,
  align: 'center'
}];
export default {
  props: {
    planVisible: {
      type: Boolean,
      default: false
    },
    contractName: {
      type: String
    }
  },
  components: {
    DollarOutlined
  },
  emits: ['closePlanVisible'],

  setup(props, context) {
    const closePlanVisible = () => {
      tableData.value = [];
      tableData1.value = [];
      context.emit('closePlanVisible', false);
    };

    const mergeCells = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      } // console.log(text,rowSpan)


      return rowSpan;
    };

    const mergeCells1 = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key] && data[index].changeNo === data[index - 1].changeNo) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (data[index].changeNo !== data[i].changeNo) {
          break;
        }

        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      }

      return rowSpan;
    };

    const mergeCells2 = (text, data, key, index) => {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key] && data[index].changeNo === data[index - 1].changeNo && data[index].changeTime === data[index - 1].changeTime) {
        return 0;
      }

      let rowSpan = 1; // 判断下一行是否相等

      for (let i = index + 1; i < data.length; i++) {
        if (data[index].changeNo !== data[i].changeNo) {
          break;
        }

        if (data[index].changeTime !== data[i].changeTime) {
          break;
        }

        if (text !== data[i][key]) {
          break;
        }

        rowSpan++;
      }

      return rowSpan;
    };

    const columns1 = [{
      title: '变更单号',
      dataIndex: 'changeNo',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text !== null ? text : "",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells(text, tableData1.value, "changeNo", index);
        return obj;
      },
      width: 180,
      align: 'center',
      fixed: 'left'
    }, {
      title: '变更时间',
      dataIndex: 'changeTime',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text !== null ? text : "",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells1(text, tableData1.value, "changeTime", index);
        return obj;
      },
      width: 120,
      align: 'center',
      fixed: 'left'
    }, {
      title: '正负变更',
      dataIndex: 'changeType',
      customRender: ({
        text,
        index
      }) => {
        const obj = {
          children: text == 0 ? '负变更' : "正变更",
          attrs: {}
        };
        obj.attrs.rowSpan = mergeCells2(text, tableData1.value, "changeType", index);
        return obj;
      },
      // slots: { customRender: 'changeType' },
      width: 100,
      align: 'center'
    }, {
      title: '序号',
      dataIndex: 'serialNo',
      width: 70,
      align: 'center'
    }, {
      title: '收支节点',
      // className: 'column-money',
      dataIndex: 'payNode',
      children: [{
        title: '节点描述',
        dataIndex: 'nodeDesc',
        // key: 'companyAddress',
        width: 320 // slots: { customRender: 'nodeDescriptor' },

      }, {
        title: '计划收支时间',
        dataIndex: 'planPayTime',
        width: 120,
        align: 'center' // key: 'paymentTime',
        // slots: { customRender: 'paymentTime' },

      }]
    }, {
      title: '收支条件',
      dataIndex: 'payTerms',
      width: 180,
      align: 'center'
    }, {
      title: '收支金额(元)',
      dataIndex: 'payAmount',
      width: 150,
      align: 'center'
    }, {
      title: '变更总金额(元)',
      dataIndex: 'totalAmount',
      width: 180,
      align: 'center'
    }, {
      title: '节点收支比例',
      dataIndex: 'payProportion',
      slots: {
        customRender: 'payProportion'
      },
      width: 180,
      align: 'center'
    }, {
      title: '节点备注',
      dataIndex: 'remark',
      width: 200
    }, {
      title: '实际收支金额(元)',
      dataIndex: 'actualPayAmount',
      width: 180,
      align: 'center'
    }, {
      title: '实际累计收支比例',
      dataIndex: 'payTotalProportion',
      slots: {
        customRender: 'payTotalProportion'
      },
      width: 180,
      align: 'center'
    }, {
      title: '收支状态',
      dataIndex: 'payState',
      slots: {
        customRender: 'payState'
      },
      width: 100,
      align: 'center'
    }]; //表格

    const tableData = ref([]);
    const tableData1 = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData(props.contractName);
    }; //获取表格数据


    const getTableData = async contractName => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        contractName: contractName
      };
      loading.value = true;

      try {
        const res = await selectPayRecordByPagination(param);

        if (res.code === 200) {
          const arr = [];
          const arr1 = [];
          tableData.value = [];
          tableData1.value = [];
          if (res.data.rows) res.data.rows.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          if (res.data.list) res.data.list.forEach(item => {
            arr1.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          Object.assign(tableData1.value, arr1);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;
          message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const handleGetTableData = val => {
      // console.log(val)
      getTableData(val);
    };

    onMounted(() => {
      getTableData(props.contractName);
    });
    return {
      paginationConfig,
      columns,
      columns1,
      tableData,
      loading,
      onPageChange,
      handleGetTableData,
      tableData1,
      closePlanVisible
    };
  }

};